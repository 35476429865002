<template>
  <div>
    <v-card>
      <v-card-title> {{ form.id > 0 ? "修改" : "新增" }}地址资源 </v-card-title>
      <v-card-text>
        <v-text-field label="域名" v-model="form.domain"></v-text-field>
        <v-text-field label="IPV6地址" v-model="form.ip_addr_v6"></v-text-field>
        <v-select
          v-model="form.status"
          item-text="name"
          item-value="code"
          :items="status_list"
          @change="
            (value) => {
              form.status = value;
            }
          "
          :rules="[() => !!form.status || '该字段是必填项']"
          label="请选择状态"
          persistent-hint
          single-line
        ></v-select>
        <v-btn
          :loading="loading"
          depressed
          color="primary"
          block
          @click="submit"
        >
          提交
        </v-btn>
        <v-btn depressed class="mt-1" block @click="cancel"> 取消 </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        id: 0,
        domain: "",
        ip_addr_v6: "",
        status: "",
      },
      status_list: [
        {
          code: "0",
          name: "未使用",
        },
        {
          code: "1",
          name: "已使用",
        },
        {
          code: "2",
          name: "已停用",
        },
      ],
    };
  },
  methods: {
    getOne() {
      let id = sessionStorage.getItem("addressPoolId");
      if (id && id > 0) {
        this.$axios
          .get(`/trunkserver/assets/address-pool/${id}/`)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.form = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submit() {
      this.loading = true;
      if (this.form.id && this.form.id > 0) {
        //修改put
        this.$axios
          .put(`/trunkserver/assets/address-pool/${this.form.id}/`, this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("addressPoolId", 0);
              this.$router.push({
                path: "/domain/address_pool",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        console.log(this.form);
        this.$axios
          .post("/trunkserver/assets/address-pool/", this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("addressPoolId", 0);
              this.$router.push({
                path: "/domain/address_pool",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    cancel() {
      sessionStorage.setItem("addressPoolId", 0);
      this.$router.push({
        path: "/domain/address_pool",
      });
    },
  },
  created() {
    this.getOne();
  },
};
</script>

<style lang="scss" scoped></style>
